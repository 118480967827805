import React, { useState, forwardRef, useImperativeHandle } from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

function DateAndTimePickers(props, ref) {
  const [value, setValue] = useState(
    props.label === "Start date"
      ? new Date()
      : new Date(Date.now() + 3600 * 1000 * 24)
  );

  useImperativeHandle(
    ref,
    () => ({
      setDateValue(val) {
        setValue(val);
      },
    }),
    []
  );
  function renderDay(props, currentDate, selectedDate) {
    return <td {...props}>{currentDate.date()}</td>;
  }
  function renderMonth(props, month, year, selectedDate) {
    return <td {...props}>{month}</td>;
  }
  function renderYear(props, year, selectedDate) {
    return <td {...props}>{year % 100}</td>;
  }

  return (
    <div style={{ width: props.width || "270px" }} className="mb-4">
      <Datetime
        onChange={(val) => {
          setValue(val);
          props.onChange(val);
        }}
        value={value}
        renderDay={renderDay}
        renderMonth={renderMonth}
        renderYear={renderYear}
      />
    </div>
  );
}

export default forwardRef(DateAndTimePickers);
